<template>
    <div class="content-wrapper">
        <validation-observer
            v-slot="{ handleSubmit }" ref="VForm"
        >
            <b-form @submit.prevent="handleSubmit(doSubmit)">

            <div class="page-header page-header-sticky page-header-sticky-open">
                <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                    <div class="page-header-info flex-1 py-3 text-uppercase">
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                        <span>{{row.ap_fullname||"-"}}</span>
                                        <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                        <span class="ml-2 pl-2 border-left">{{rowReg.ar_reg_code||"-"}}</span>
                                        <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                    </h5>
                                </div>
                                <div class="phi-sub-info d-flex">
                                    <div class="phi-info d-flex flex-column">
                                        <small>Tanggal Lahir</small>
                                        <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                    </div>
                                    <div class="phi-info d-flex flex-column pl-2 ml-3">
                                        <small>Usia</small>
                                        <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br /> 
                                        ({{row.ap_gol_usia||"-"}})</strong>
                                    </div>
                                    <div class="phi-info d-flex flex-column pl-2 ml-3">
                                        <small>Jenis Kelamin</small>
                                        <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                    </div>
                                    <div class="phi-info d-flex flex-column pl-2 ml-3">
                                        <small>Opsi Pembayaran</small>
                                        <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                    </div>
                                    <div class="phi-info d-flex flex-column pl-2 ml-3">
                                        <small>Agama</small>
                                        <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="phi-info d-flex flex-column ml-auto">
                                <small>Dokter</small>
                                <h5 class="phi-main font-weight-semibold mb-0">
                                    <span>{{rowReg.bu_full_name||"-"}}</span>
                                </h5>
                                <div class="mb-2">
                                    <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
                <i class="icon-arrow-up5 align-middle"></i>
                <span class="align-middle">Sembunyikan Informasi Pasien</span>
                </a>
            </div>
            <div class="content">
                <div class="card">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pengkajian Resiko Jatuh</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row">
                        <div class="col-md-auto">
                        <div class="label_code">
                            <h2>A</h2>
                        </div>
                        </div>
                        <div class="col-md-11">
                        <div class="row">
                            <div class="col-md-12">
                                <b-form-group label="Cara Berjalan pasien (salah satu atau lebih) :">
                                    <b-form-checkbox-group
                                        class="mt-2 checkbox-block"
                                        v-model="row.akp_pengkajian_A"
                                        :options="Config.mr.keselamatanPasienA"
                                        @input="changeTindakan()"
                                        name="ca"
                                    ></b-form-checkbox-group>
                                </b-form-group>
                            </div>
                        </div>
                        </div>
                    </div>
                    <hr class="small_line">
                    <div class="row">
                        <div class="col-md-auto">
                        <div class="label_code">
                            <h2>B</h2>
                        </div>
                        </div>
                        <div class="col-md-11">
                        <div class="row">
                            <div class="col-md-12">
                                <b-form-group label="">
                                    <b-form-checkbox-group
                                        class="mt-2"
                                        id="checkbox-block-4"
                                        v-model="row.akp_pengkajian_B"
                                        :options="Config.mr.keselamatanPasienB"
                                        @input="changeTindakan()"
                                        name="cb"
                                    ></b-form-checkbox-group>
                                </b-form-group>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-md-7">
                        <table class="table table-bordered table-striped table-hover table-sm patient-table">
                            <thead>
                            <tr>
                                <th>HASIL</th>
                                <th>Tindakan</th>
                                <th>Aksi</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (row.akp_tindakan||[])" :key="k+'tindakan'">
                                <td v-if="k==0" :rowspan="(row.akp_tindakan||[]).length">{{hasil}}</td>
                                <td>
                                    <span v-if="(v == 'Pasang pita kuning'||v == 'edukasi') || hasil !== 'Beresiko Tinggi'">{{v}}</span>
                                    <span v-else>
                                        <b-form-input v-model="row.akp_tindakan[k]" :formatter="$parent.normalText" type="text" name="name" class="form-control"/>
                                        <VValidate 
                                            :name="'Tindakan #'+(k+1)" message="Tindakan Harus Diisi"
                                            v-model="row.akp_tindakan[k]" 
                                            :rules="{required : 1}"
                                        />
                                    </span>
                                </td>
                                <td>
                                    <span v-if="(v == 'Pasang pita kuning'||v == 'edukasi') || hasil !== 'Beresiko Tinggi'"> - </span>
                                    <a v-else href="javascript:;" class="list-icons-item"
                                    @click="(row.akp_tindakan||[]).splice(k,1)"
                                    data-toggle="tooltip" data-placement="top" title="Delete"><i
                                        class="icon-bin"></i></a>
                                </td>
                            </tr>
                            <tr v-if="hasil == 'Beresiko Tinggi'">
                                <td colspan="99" class="text-center">
                                    <a href="javascript:;" @click="(row.akp_tindakan||[]).push('')" class="btn btn-outline-primary btn-sm font-weight-semibold">Tambah Tindakan</a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="text-right">
                        <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default{
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        Config: Object
    },
    
    // data(){
    //     return {
    //         deletedData : []
    //     }
    // },
    
    mounted(){
        setTimeout(()=>{
            this.initSticky()
        },1000)
    },
    computed: {
        hasil(){
            let text = ''
            if(!(this.row.akp_pengkajian_A||[]).length && !(this.row.akp_pengkajian_B||[]).length){
                text = 'Tidak Berisiko'
            }else if((this.row.akp_pengkajian_A||[]).length && (this.row.akp_pengkajian_B||[]).length){
                text = 'Beresiko Tinggi'
            }else{
                text = 'Berisiko Sedang'
            }   
            return text
        }
    },
    methods: {
        initSticky(){
            const PageHeaderSticky = document.querySelector('.page-header-sticky')
            if(PageHeaderSticky){    
                const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                    PageHeaderToggle.addEventListener('click', function(e){
                    // e.preventDefault()
                    PageHeaderSticky.classList.toggle('page-header-sticky-open')
                    if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                        PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                    }else{
                        PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                    }
                    })    
                } 
            }
        },
        changeTindakan(){
            if(!(this.row.akp_pengkajian_A||[]).length && !(this.row.akp_pengkajian_B||[]).length){
                this.row.akp_tindakan = ['Tidak Ada Tindakan']
            }else if((this.row.akp_pengkajian_A||[]).length && (this.row.akp_pengkajian_B||[]).length){
                this.row.akp_tindakan = ['Pasang pita kuning','edukasi']
            }else{
                this.row.akp_tindakan = ['Edukasi']
            }   
        },  
        addNew(){
            this.dataHais.push({
                aha_ar_id : this.$parent.pageSlug,
                aha_kategori : null, 
                aha_komponen : [],
                aha_kejadian : [],
                aha_id : null   
            })
        },
        deleteHais(k,v){
            if(v.aha_id){
                this.deletedData.push(v.aha_id)
            }
            this.dataHais.splice(k,1)
        },
        doSubmit(){
            this.$refs['VForm'].validate().then(success=>{
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'add'
                            data.akp_hasil = this.hasil
                            data.akp_ar_id = this.$parent.pageSlug

                            this.$parent.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+this.$parent.modulePage,
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.$parent.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok'
                                }).then(result => {
                                    if (result.value) {
                                        this.$router.push({name:this.$route.name}).catch(()=>{})
                                    }
                                })
                            }).catch(err=>{
                                this.$parent.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                            
                        }
                    })
                }else{
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
        },
        toValidate(val){
            return {...val}
        }
    }
}
</script>
